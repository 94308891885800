@import '../../theme/_global-styles';
@import '../../theme/variables';
@import '../../theme/nats-brand-guidelines';
@import '../General/NonAuthContainer';

.changePasswordPage {
  @include formPage;

  padding-bottom: 20px;

  .info {
    margin-bottom: 20px;
    width: 420px;
    margin: 0 auto 20px auto;
    font-size: 14px;
  }

  a {
    font-family: $nats-regular-font-family;
    color: $twilight-blue;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.changePasswordContainer {
  @extend .nonAuthContainer;
  overflow-y: auto;
  height: calc(100% - (#{$headerHeight} + 30px));

  p {
    margin-bottom: 14px;
  }
}

.cancelDiv {
  margin-top:10px;
  text-align: center;
}
