@import '../../theme/variables';
@import '../General/NonAuthContainer';

.termsBox {
  width: 830px;
  font-family: Roboto Regular,sans-serif;
  font-size: 14px;
  background-color: #f1f1f0;
  color: #161616;
  padding: 20px 35px;
  margin: 60px auto;
}

.termsBox > p {
  margin-bottom: 14px;
}

strong {
  font-family: $nats-bold-font-family;
  font-weight: 400;
}

.tosText {
  ol {
    padding-left: $defaultSpacing;
  }
  ol ol {
    padding-left: 22px;
    list-style: none;
    > li:before {
      counter-increment: roman;
      content:"(" counter(roman, lower-roman)") ";
      margin-right:4px;
    }
  }
  li {
    color: $midnight-blue;
    margin-bottom: $defaultSpacing;
  }
  ol ul, ol ol {
    margin-top: 8px;
  }
  a {
    color: $twilight-blue;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
}

.termsBox h1 {
  margin: 21px 0;
  font-size:30px;
  font-family: $nats-regular-font-family;
  color: $twilight-blue;
  font-weight:bold;
}

.tosButton {
  margin-left:0;
  margin-bottom: 0;
  width:30%;
  height:60px;
  font-family: $nats-regular-font-family;
  font-size:20px;
  background-color: $twilight-blue;
  line-height:57px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  touch-action: manipulation;
  font-weight: normal;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  border-radius: 3px;
}

.tosButton:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.tosButton:hover {
  background-color: $twilight-blue-80;
}
