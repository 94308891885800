@import './theme/variables.scss';
@import './theme/nats-brand-guidelines.scss';
@import './theme/colors.scss';

@import "~bootstrap-sass/assets/stylesheets/bootstrap";
@import "~font-awesome/scss/font-awesome";

html {
  font-size: 16px;
  font-family: $nats-font-family;
}

body{
  margin: 0;
  padding: 0;
  color: $dark-text-font-color;
  font-family: $nats-font-family;
}