@import './nats-brand-guidelines';

/**
 *  Define scss variables here.
 *
 *  Available options for Bootstrap:
 *  Available options for Bootstrap:
 *  http://getbootstrap.com/customize/
 *
 */
 $icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
 
// colours introduced with NATS rebrand 2017
$main-heading-background-color: $midnight-blue;
$background-gradient: $bg-gradient;
$light-text-font-color: #ffffff;
$dark-text-font-color: $midnight-blue;
$button-background-color: $twilight-blue-60;
$button-hover-color: $twilight-blue-40;
$active-tab-color: $twilight-blue;
$inactive-tab-color: $twilight-blue-60;
$icon-color: $twilight-blue;
$icon-color-hover: $twilight-blue-60;
$primary-alert-color: $accent-pink;
$secondary-alert-color: $accent-orange;
$heading-highlight-color: $accent-blue;
$link-color: $accent-blue;

// Analytics
$card-heading-bg-color: $twilight-blue;
$poor-metric-color: $accent-pink;
$poor-metric-light-color: $accent-pink-50;
$bad-metric-color: $accent-orange;
$bad-metric-light-color: $accent-orange-50;
$good-metric-color: $accent-green;
$good-metric-light-color: $accent-green-50;
$neutral-metric-color: $midnight-blue-40;
$neutral-metric-light-color: $midnight-blue-20;
$neutral-metric-color-2: $twilight-blue-40;
$neutral-metric-light-color-2: $twilight-blue-20;
$market-comparison-color: #558392;
$axis-color: $accent-blue;
$selected-heading-color: $twilight-blue;
$card-background-color: #fff;
$visual-font-family: $nats-font-family;
$body-background-color: $twilight-blue;
$label-color: $twilight-blue;
$distance-flown-arrow-color: $twilight-blue-60;
$large-number-color: #777;
$plannedBgColor: #f7f7f7;
$actualBgColor: #e7eef0;
$sort-icon-color: $midnight-blue;
$sort-icon-active-color: $accent-pink;
$info-panel-color: $accent-blue-60;
$info-panel-icon-color: $twilight-blue;
$standard-text-and-line-color: $twilight-blue;
$standard-warn-color: $accent-pink;
$visual-bar-color1: $twilight-blue;
$visual-bar-color2: #c1c0c4;

$pagination-active-button: $twilight-blue;

$nav-bar-drop-shadow: 0px 2px 4px rgba(0,0,0,0.35);

///

$tab-heading-font-family: $nats-font-family;
$logo-font-family: $nats-font-family;

// Custom Colors
$cyan: #33e0ff;
$humility: #777;
$light-blue: #00BAE7;
$graph-background: #F7F7F7;
$graph-text: #A9B0B2;
$mouse-line-colour: #b3ebff, 0.5;
$market-line-colour: #F39D2A;

// Bootstrap Variables
$brand-primary: darken(#428bca, 6.5%);
$brand-secondary: #e25139;
$brand-success: $twilight-blue-60;
$brand-warning: #f0ad4e;
$brand-danger: #d9534f;
$brand-info: #5bc0de;

$navbar-default-bg: white;
$navbar-default-link-color: black;
$navbar-default-link-hover-color: black;
$navbar-default-color: black;

$border-radius-base: 3px;

$text-color: $light-text-font-color;

$link-color: black;

$font-size-base: 14px;
$font-family-sans-serif: $nats-font-family;
$headings-font-family: $nats-font-family;
$card-title-regular-font-family: $nats-regular-font-family;

$menubar-primary: $twilight-blue;
$menubar-border: $twilight-blue;
$menubar-icon: darken($menubar-primary, 10%);
$menubar-icon-highlighted: $menubar-primary;
$menubar-light: lighten($menubar-primary, 30%);
$menubar-text: lighten($menubar-primary, 90%);

$alert-colour-sigmet: #a5a9dc;
$alert-colour-red-alert: $accent-pink;
$alert-colour-amber-alert: $accent-orange;
$alert-colour-red-icon: $accent-pink;
$alert-colour-amber-icon: #00BAE7;
$alert-colour-green-icon: $accent-green;
$alert-colour-grey-icon: #0085CF;
$alert-colour-default: $twilight-blue;

$modal-header: $twilight-blue;
$modal-header-alert: $brand-warning;

$button: $twilight-blue;
$button-hover: $twilight-blue;
$button-disabled: gray;

@function alert-colour-alt1($alert-colour) {
  @return lighten($alert-colour, 38%);
}

@function alert-colour-alt2($alert-colour) {
  @return lighten(desaturate($alert-colour, 25%), 30%);
}

$headerHeight: 130px;
$defaultSpacing: 16px;