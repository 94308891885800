@import '../../theme/nats-brand-guidelines';

.App {
  height: 100vh;
}

/**
  Temporary measure until webclient-common scopes Bootstrap correctly.
  Ticket: https://jupiter.bjss.com/browse/NATSMOS-2410
 */
:global {
  #root {
    font-family: $nats-font-family, sans-serif;
    line-height: initial;

    img {
      vertical-align: initial;
    }
  }

  input.form-control:focus {
    box-shadow: none;
    border-color: inherit;
  }
}
