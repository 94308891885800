@import '../../theme/variables';
@import '../../theme/nats-brand-guidelines';

$menu-item-font-size: 24px;

.header {
  position: relative;
  height: $headerHeight;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $main-heading-background-color;
  color: $light-text-font-color;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
  a {
    color: $light-text-font-color;
  }
  p {
    margin: 0 0 10px 60px;
    font-size: 13.5px;
  }
}

.logoWrapper {
  padding-top: 5px;
  height: 110px;
}

.logoHeader {
  width: 78px;
  margin-left: 15px;
  margin-right: 5px;
  display: inline-block;
}

.headerTop {
  height: 100%;
  position: relative;
}

.navigationLogo {
  width: 166.98px;
  height: 40.2px;
  margin-left: 45px;
  margin-right: 7px;
  margin-top: 42px;
  display: inline-block;
}

.modLogo {
  float: right;
  height: auto;
  max-height: 100px;
  display: inline-block;
  margin: 15px 30px -35px 45px;
}

.mileEAMS {
  font-size: 25px;
}

.rightMenu {
  list-style-type: none;
  margin: 25px 20px 0 0;
  padding: 0;
  > li {
    height: 32px;
    float: left;
    margin-left: 20px;
    > a {
      display: block;
      height: 32px;
      line-height: 32px;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

.rightMenuItem {
  display: inline-block;
  position: relative;
  border: 0;
  background: transparent;
  box-shadow: none;
  padding-top: 2px;
  cursor: pointer;

  > i {
    line-height: 25px;
    color: $icon-color;
  }
}

.lightRightMenuItem {
  display: inline-block;
  position: relative;
  border: 0;
  background: transparent;
  box-shadow: none;
  padding-top: 2px;

  > i {
    line-height: 25px;
    color: $light-text-font-color;
  }
}

.rightMenuItemHeader {
  padding-top: 5px;
  padding-bottom: 9px;
  background-color: $menubar-light;
  color: $dark-text-font-color;
}

.rightMenuPanel {
  position: absolute;
  right: 0;
  top: 35px;
  width: 144px;
  border: 0.5px solid $menubar-border;
  background-color: $menubar-primary;
  box-shadow: none;
  color: $light-text-font-color;
  text-align: center;
  text-decoration: none;

  > ul {
    display: inline;
    margin: 0;
    padding: 0;

    > li {
      background: $menubar-primary;
      display: block;
      padding-top: 5px;
      padding-bottom: 9px;
      border-top: 0.5px solid $menubar-border;
      color: $light-text-font-color;
      cursor: pointer;

      &:hover {
        background: $menubar-light;
        border-top: 0.5px solid $menubar-border;
        color: $dark-text-font-color;
        a {
          color: $dark-text-font-color;
        }
      }
      a:hover {
        color: $dark-text-font-color;
      }
    }
  }
}

.rightMenuTooltip {
  position: absolute;
  right: 0;
  top: 35px;
  width: 100px;
  border: 0.5px solid $menubar-border;
  background-color: $menubar-light;
  box-shadow: none;
  color: $light-text-font-color;
  text-align: center;
  padding: 5px;
}

.logoutLink {
  @extend li;
  list-style-type: none;
}

.analyticsTitle {
  font-size: $menu-item-font-size;
  margin: 0 0 0 10px;
}

ul.menu {
  list-style: none;
  height: 30px;
  font-family: $headings-font-family;
  display: inline-block;
  margin: 0 0 0 10px;
  padding: 0;
}

.menuItem {
  display: inline-block;
  font-size: $menu-item-font-size;
  background-color: $selected-heading-color;
  color: $light-text-font-color;
  padding: 3px 10px;
}

.environmentLabel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: $accent-orange;
  font-size: 20px;
  font-weight: bolder;
}
