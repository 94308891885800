@import '../../theme/_global-styles';
@import '../../theme/variables';
@import '../General/NonAuthContainer';

.newPasswordPage {
  @include formPage;
  .info {
    margin-bottom: 20px;
    width: 420px;
    margin: 0 auto 20px auto;
    font-size: 14px;
  }
}

.newPasswordContainer {
  @extend .nonAuthContainer;
  overflow-y: auto;
}
