

// https://css-tricks.com/snippets/sass/tint-shade-functions/
/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

$midnight-blue: #071920;
$midnight-blue-80: tint($midnight-blue, 20);
$midnight-blue-60: tint($midnight-blue, 40);
$midnight-blue-40: tint($midnight-blue, 60);
$midnight-blue-20: tint($midnight-blue, 80);

$twilight-blue: #145569;
$twilight-blue-80: tint($twilight-blue, 20);
$twilight-blue-60: tint($twilight-blue, 40);
$twilight-blue-40: tint($twilight-blue, 60);
$twilight-blue-20: tint($twilight-blue, 80);

$accent-blue: #46C3D7;
$accent-blue-80: tint($accent-blue, 20);
$accent-blue-60: tint($accent-blue, 40);
$accent-blue-40: tint($accent-blue, 60);
$accent-blue-20: tint($accent-blue, 80);

$accent-pink: #B93296;
$accent-pink-80: tint($accent-pink, 20);
$accent-pink-60: tint($accent-pink, 40);
$accent-pink-50: tint($accent-pink, 50);
$accent-pink-40: tint($accent-pink, 60);
$accent-pink-20: tint($accent-pink, 80);

$accent-green: #50C364;
$accent-green-80: tint($accent-green, 20);
$accent-green-60: tint($accent-green, 40);
$accent-green-50: tint($accent-green, 50);
$accent-green-40: tint($accent-green, 60);
$accent-green-20: tint($accent-green, 80);

$accent-orange: #E66E0A;
$accent-orange-80: tint($accent-orange, 20);
$accent-orange-60: tint($accent-orange, 40);
$accent-orange-50: tint($accent-orange, 50);
$accent-orange-40: tint($accent-orange, 60);
$accent-orange-20: tint($accent-orange, 80);


$bg-gradient: linear-gradient(0deg, rgba(20,85,105,1) 82%, rgba(7,25,32,1) 100%, rgba(7,25,32,1) 100%);

$nats-font-family: 'Roboto Light', sans-serif;
$nats-regular-font-family: 'Roboto Regular', sans-serif;
$nats-bold-font-family: 'Roboto Bold', sans-serif;
