@import '../../theme/_global-styles';
@import '../../theme/variables';
@import '../../theme/nats-brand-guidelines';
@import '../General/NonAuthContainer';

.forgotPasswordContainer {
  @extend .nonAuthContainer;
  overflow-y: auto;
}

.forgotPasswordPage {
  @include formPage;
}

.login {
  color: $midnight-blue;
  text-align: center;
  margin-top: 10px;

  a {
    font-family: $nats-regular-font-family;
    color: $twilight-blue;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
