// global styles used across the whole app (no size or color related variables)
@import './metrics';
@import './variables';
@import '../theme/nats-brand-guidelines';

@mixin fixedHeightContent {
  height: calc(100vh - #{$header-height});
  overflow-y: hidden;
}

@mixin fixedMinHeightContent {
  min-height: calc(100vh - #{$header-height});
}

@mixin verticallyScrollable {
  overflow-y: auto;
  height: calc(100% - #{$sidebar-tabs-height});
}

@mixin noScrollbars { // Hiding sidebars
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
}

@mixin formPage {
  padding-bottom: 50px;
  margin: 0 auto;
  margin-top: 60px;
  background-color: #F1F1F0;
  color: $twilight-blue;
  width: 768px;
  h1 {
    font-family: $nats-regular-font-family;
    font-size: 30px;
    margin: 40px 0px 38px 0px;
    text-align: center;
    font-weight: bold;
    line-height: normal;
  }

  .inputWrapper {
    margin-bottom:20px;
    border: 1px solid #ccc;
    border-radius: 6px;
    width: 100%;

    .inputIconWrapper {
      box-sizing: border-box;
      width: 10%;
      display: inline-block;
      text-align: center;
      vertical-align: sub;
    }
  }

  input {
    display: inline-block;
    padding: 0px 10px;
    box-sizing: border-box;
    height: 44px;
    border-radius: 0px 5px 5px 0px;
    border: none;
    border-left: 1px solid #ccc;
    background-color: #fff;
    font-size: 18px;
    color: #777;
    font-family: $nats-font-family;
    -webkit-box-shadow: 0 0 0 50px white inset;
    width: 90%;
  }

  input:focus {
    outline-width: 0;
  }

  .innerFormPage {
    padding-top:1px;
  }

  .form {
    width:450px;
    margin: 0 auto;
    font-size:16px;
    font-family: $nats-regular-font-family;
    :global(.btn) {
      margin-left:0px;
      width:100%;
      height:60px;
      font-family: $nats-regular-font-family;
      font-size:20px;
      background-color: $twilight-blue;
      color: #fff;
      border: 0;
      cursor: pointer;
    }
    :global(.btn:hover) {
      background-color: $twilight-blue-80;
    }
  }
}

.msgBox {
  padding: 30px 15px 15px 15px;
  border: 1px solid #000;
  border-radius: 3px;
  width: 450px;
  margin: 0 auto 20px auto;
  font-size: 14px;
  font-weight: bold;
}

.dangerMsg {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}

.infoMsg {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #fff;
}

.successMsg {
  background-color: $accent-green-20;
  border-color: $accent-green-60;
  color: darken($accent-green, 20%);
}

.rippleEffect {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background: no-repeat radial-gradient(circle, #000 10%, transparent 10.01%) 50%;
    transform: scale(10,10);
    opacity: 0;
    transition: transform .5s, opacity 1s;
  }

  &:active:after {
    transform: scale(0,0);
    opacity: .2;
    transition: 0s;
  }
}
