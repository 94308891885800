@import '../../theme/variables.scss';

.homeContainerMileams {
  width: 100%;
  height: calc(100% - (#{$headerHeight} + 30));
  overflow: hidden;
}

.contentContainer {
  height: 100vh !important;
  background-size: cover !important;
  margin: 0 auto;
  padding-top: 50px;
  box-sizing: content-box;
}

.contentContainerMilitary {
  background: url('../../static/home-page/nats-military-flight-path-01-accent-blue-rgb.png') no-repeat, linear-gradient(to bottom, #071920, #0b2c38 49%, #145569) no-repeat, #145569;
}

.contentContainerCivil {
  background: url('../../static/home-page/NATS_Large_Civil_FlightPath01_AccentBlue_RGB.png') no-repeat 0 120px,linear-gradient(180deg,#071920,#0b2c38 49%,#145569) no-repeat,#145569;
}

@media (max-height: 900px)  {
  .contentContainer {
    height: calc(100% + 50px);
  }
}

.grid {
  position: relative;
  justify-content: space-between;
  padding-bottom:40px;
  max-width:1200px;
  margin:0 auto;
  .col {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }

  .col:hover {
    img {
      opacity: 0.5;
    }
  }

  .menuItem {
    margin: 0 auto;
    max-width: 230px;
    max-height: 200px;
    background-color: #145569;
    box-shadow: 2px 4px 7px 0 rgba(0, 0, 0, 0.3);
    text-align:center;
    cursor: pointer;
    span {
      display:inline-block;
      color:#fff;
      font-size: 18px;
      font-weight: 300;
      padding: 10px 0;
      white-space: nowrap;
    }
    @media screen and (max-width: 950px) {
      span {
        font-size: 14px;
      }
    }
    @media screen and (max-width: 740px) {
      span {
        font-size: 10px;
      }
    }

    img {
      width: 95%;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-user-drag: none;
      user-drag: none;
      -webkit-touch-callout: none;
    }
  }
}

.titleContainer {
  width: 95%;
  color: #fff;
  display: grid;
  padding-bottom: 40px;
  padding-left: 50px;
}

.mappTitle {
  margin-bottom: 30px;
  display: inline-block;
  font-size: 28px;
  font-weight: 600;
}

.availableApps {
  display: block;
  font-size: 24px;
  font-weight: 300;
}

.react-grid-item.react-grid-placeholder {
  background: none !important;
}
