@import '../../theme/_global-styles';
@import '../../theme/variables';
@import '../General/NonAuthContainer';

.loginPage {
  @include formPage;
  .termsAboveLogin {
    color: $midnight-blue;
    margin-bottom: 10px !important;
    a {
      color: $twilight-blue;
      text-decoration: underline;
    }
    a:hover {
      text-decoration: none;
    }
  }

  .forgotPassword {
    color: $midnight-blue;
    text-align: center;
    margin-top: 10px;

    a {
      color: $twilight-blue;
      text-decoration: underline;
    }
    a:hover {
      text-decoration: none;
    }
  }

  .loginButtonDisabled, .loginButtonDisabled:hover {
    cursor: default !important;
    background-color: $twilight-blue !important;

  }
}

.loginIcon {
  margin-right: 10px;
}
.loginBars, .loginBars:before, .loginBars:after {
  background-color: #ffffff;
}
