@import '../../theme/variables';
@import '../General/NonAuthContainer';

.redirectBox {
  width: 760px;
  font-family: Roboto Regular,sans-serif;
  font-size: 14px;
  background-color: #f1f1f0;
  color: #161616;
  padding: 20px 35px;
  margin: 0px auto;
  margin-top: 60px;
}

.redirectBox h1 {
  font-size:30px;
  font-family: $nats-regular-font-family;
  color: $twilight-blue;
  font-weight:bold;
}
