@import '../../theme/_global-styles';
@import '../../theme/variables';
@import '../General/NonAuthContainer';

.noAppPermissionPage {
  @include formPage;

  .info {
    width: 420px;
    margin: 0 auto 20px;
    font-size: 14px;
  }
}

.noAppPermissionContainer {
  @extend .nonAuthContainer;
  height: calc(100% - (#{$headerHeight} + 30px));
}
